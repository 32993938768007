import track from 'react-tracking';
import { createMiddleware } from 'redux-beacon';
import Itly, {
  PageViewedProperties,
  CartViewedProperties,
  OrderCompletedProperties,
  ProductViewedProperties,
  OrderItemCompletedProperties,
  ProductListViewedProperties,
  CheckoutStartedProperties,
  CheckoutStepViewedProperties,
  CheckoutStepCompletedProperties,
  ProductAddedProperties,
  ClpEmptyResultsProperties,
  FilterDeselectedProperties,
  FilterSelectedProperties,
  ProjectDeletedProperties,
  ProjectDuplicatedProperties,
  ProjectEditedProperties,
  SearchResultSelectedProperties,
  FirstPurchaseProperties,
  CheckoutErrorProperties,
  NewCardAddedProperties,
  ApplyPromoCodeProperties,
  ApplyPromoCodeErrorProperties,
  ComponentLoadedProperties, CheckoutPageTotalLoadTimeProperties
} from 'itly';
import { CartActionType } from 'store/cart/constants';
import { checkIsDevelopmentBuild } from 'helpers/http';
import { mapPlaceOrderSuccessToEvent } from './beacons/mapPlaceOrderSuccessActionToEvent';
import { mapFirstPurchaseActionToEvent } from './beacons/mapFirstPurchaseActionToEvent';
import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import { mapCheckoutStepShippingCompletedActionToEvent } from './beacons/mapCheckoutStepShippingCompletedActionToEvent';
import { mapProductAddedActionToEvent } from './beacons/mapProductAddedActionToEvent';
import { analyticsIdentifyCustomer } from '../identifyProviders';
import { mapCheckoutErrorActionToEvent } from './beacons/mapCheckoutErrorActionToEvent';
import { mapNewCardAddedActionToEvent } from './beacons/mapNewCardAddedActionToEvent';
import { mapApplyPromoCodeToEvent } from './beacons/mapApplyPromoCodeToEvent';
import { mapApplyPromoCodeErrorToEvent } from './beacons/mapApplyPromoCodeErrorToEvent';
import { ComponentType } from 'react-spring';

export interface PageViewedEvent {
  type: 'pageViewed';
  data: PageViewedProperties;
}

export interface CartViewedEvent {
  type: 'cartViewed';
  data: CartViewedProperties;
}

export interface OrderCompletedEvent {
  type: 'orderCompleted';
  data: {
    byOrder: OrderCompletedProperties;
    byItem: OrderItemCompletedProperties[];
  };
}

export interface PasswordResetStartedEvent {
  type: 'passwordResetStarted';
}

export interface ProductViewedEvent {
  type: 'productViewed';
  data: ProductViewedProperties;
}

export interface ProductListViewedEvent {
  type: 'productListViewed';
  data: ProductListViewedProperties;
}

export interface AccountCreatedEvent {
  type: 'accountCreated';
  data: { customer: CustomerDataCustomer };
}

export interface LoggedInEvent {
  type: 'loggedIn';
  data: { customer: CustomerDataCustomer };
}

export interface CheckoutStartedEvent {
  type: 'checkoutStarted';
  data: CheckoutStartedProperties;
}

export interface CheckoutStepViewedEvent {
  type: 'checkoutStepViewed';
  data: CheckoutStepViewedProperties;
}

export interface FirstPurchaseEvent {
  type: 'firstPurchase',
  data: FirstPurchaseProperties
}

export interface CheckoutStepCompletedEvent {
  type: 'checkoutStepCompleted';
  data: CheckoutStepCompletedProperties;
}

export interface ProductAddedEvent {
  type: 'productAdded';
  data: ProductAddedProperties;
}

export interface GleamEmailSubmittedEvent {
  type: 'gleamEmailSubmitted';
  data: { customer: CustomerDataCustomer };
}

export interface CLPEmptyResultsEvent {
  type: 'clpEmptyResults';
  data: ClpEmptyResultsProperties;
}

export interface FilterSelectedEvent {
  type: 'filterSelected';
  data: FilterSelectedProperties;
}

export interface FilterDeselectedEvent {
  type: 'filterDeselected';
  data: FilterDeselectedProperties;
}

export interface ProjectDeletedEvent {
  type: 'projectDeleted';
  data: ProjectDeletedProperties;
}

export interface ProjectDuplicatedEvent {
  type: 'projectDuplicated';
  data: ProjectDuplicatedProperties;
}

export interface ProjectEditedEvent {
  type: 'projectEdited';
  data: ProjectEditedProperties;
}

export interface SearchResultSelectedEvent {
  type: 'searchResultSelected';
  data: SearchResultSelectedProperties;
}

export interface SearchStartedEvent {
  type: 'searchStarted';
}

export interface CheckoutErrorEvent {
  type: 'checkoutError';
  data: CheckoutErrorProperties;
}

export interface ComponentLoadedEvent {
  type: 'componentLoaded',
  data: ComponentLoadedProperties;
}

export interface CheckoutPageTotalLoadTimeEvent {
  type: 'checkoutPageTotalLoadTime',
  data: CheckoutPageTotalLoadTimeProperties;
}

export interface NewCardAddedEvent {
  type: 'newCardAdded';
  data: NewCardAddedProperties;
}

export interface ApplyPromoCodeEvent {
  type: 'applyPromoCode';
  data: ApplyPromoCodeProperties;
}

export interface ApplyPromoCodeErrorEvent {
  type: 'applyPromoCodeError';
  data: ApplyPromoCodeErrorProperties;
}

type AnalyticsEvent =
  | PageViewedEvent
  | CartViewedEvent
  | OrderCompletedEvent
  | PasswordResetStartedEvent
  | ProductViewedEvent
  | ProductListViewedEvent
  | AccountCreatedEvent
  | LoggedInEvent
  | CheckoutStartedEvent
  | CheckoutStepViewedEvent
  | CheckoutStepCompletedEvent
  | ProductAddedEvent
  | GleamEmailSubmittedEvent
  | FilterDeselectedEvent
  | FilterSelectedEvent
  | CLPEmptyResultsEvent
  | ProjectEditedEvent
  | ProjectDeletedEvent
  | ProjectDuplicatedEvent
  | SearchResultSelectedEvent
  | SearchStartedEvent
  | FirstPurchaseEvent
  | CheckoutErrorEvent
  | ComponentLoadedEvent
  | CheckoutPageTotalLoadTimeEvent
  | NewCardAddedEvent
  | ApplyPromoCodeEvent
  | ApplyPromoCodeErrorEvent;

function dispatchItly(event: AnalyticsEvent): void {
  if (checkIsDevelopmentBuild()) {
    console.debug(`[dispatchItly] ${event.type || 'Unknown'}`, event);
  }

  switch (event.type) {
    case 'pageViewed': {
      const { page_name, page_type } = event.data;
      Itly.page(page_type, page_name);
      Itly.pageViewed({ page_name, page_type });
      return;
    }
    case 'cartViewed': {
      Itly.cartViewed(event.data);
      return;
    }
    case 'orderCompleted': {
      Itly.orderCompleted(event.data.byOrder);
      event.data.byItem.forEach(item => Itly.orderItemCompleted(item));
      return;
    }
    case 'passwordResetStarted': {
      Itly.passwordResetStarted();
      return;
    }
    case 'accountCreated': {
      const { customer } = event.data;
      Itly.accountCreated({ user_id: customer.id, email: customer.email, site_id: 'us:web' });
      analyticsIdentifyCustomer(customer);
      return;
    }
    case 'loggedIn': {
      const { customer } = event.data;
      analyticsIdentifyCustomer(customer);
      return;
    }
    case 'productViewed': {
      Itly.productViewed(event.data);
      return;
    }
    case 'productListViewed': {
      Itly.productListViewed(event.data);
      return;
    }
    case 'checkoutStarted': {
      Itly.checkoutStarted(event.data);
      return;
    }
    case 'checkoutStepViewed': {
      Itly.checkoutStepViewed(event.data);
      return;
    }
    case 'firstPurchase': {
      Itly.firstPurchase(event.data);
      return;
    }
    case 'checkoutStepCompleted': {
      Itly.checkoutStepCompleted(event.data);
      return;
    }
    case 'productAdded': {
      Itly.productAdded(event.data);
      return;
    }
    case 'clpEmptyResults': {
      Itly.clpEmptyResults(event.data);
      return;
    }
    case 'filterDeselected': {
      Itly.filterDeselected(event.data);
      return;
    }
    case 'filterSelected': {
      Itly.filterSelected(event.data);
      return;
    }
    case 'gleamEmailSubmitted': {
      const { customer } = event.data;
      analyticsIdentifyCustomer(customer);
      return;
    }
    case 'projectDeleted': {
      Itly.projectDeleted(event.data);
      return;
    }
    case 'projectDuplicated': {
      Itly.projectDuplicated(event.data);
      return;
    }
    case 'projectEdited': {
      Itly.projectEdited(event.data);
      return;
    }
    case 'searchResultSelected': {
      Itly.searchResultSelected(event.data);
      return;
    }
    case 'searchStarted': {
      Itly.searchStarted();
      return;
    }
    case 'checkoutError': {
      Itly.checkoutError(event.data);
      return;
    }
    case 'componentLoaded': {
      Itly.componentLoaded(event.data);
      return;
    }
    case 'checkoutPageTotalLoadTime': {
      Itly.checkoutPageTotalLoadTime(event.data);
      return;
    }
    case 'newCardAdded': {
      Itly.newCardAdded(event.data);
      return;
    }
    case 'applyPromoCode': {
      Itly.applyPromoCode(event.data);
      return;
    }
    case 'applyPromoCodeError': {
      Itly.applyPromoCodeError(event.data);
      return;
    }
  }
}

function itlyReduxBeaconTarget(events) {
  events.map(dispatchItly);
}

const itlyReduxBeaconEventsMap = {
  [CartActionType.ANALYTICS_PRODUCT_ADDED_TO_CART]: mapProductAddedActionToEvent,
  [CartActionType.ANALYTICS_TRACK_FIRST_PURCHASE]: mapFirstPurchaseActionToEvent,
  [CartActionType.ANALYTICS_TRACK_CHECKOUT_STEP_COMPLETED]: mapCheckoutStepShippingCompletedActionToEvent,
  [CartActionType.PLACE_ORDER_SUCCESS]: mapPlaceOrderSuccessToEvent,
  [CartActionType.ANALYTICS_TRACK_CHECKOUT_ERROR]: mapCheckoutErrorActionToEvent,
  [CartActionType.ANALYTICS_TRACK_NEW_CARD_ADDED]: mapNewCardAddedActionToEvent,
  [CartActionType.ANALYTICS_APPLY_PROMO_CODE]: mapApplyPromoCodeToEvent,
  [CartActionType.ANALYTICS_APPLY_PROMO_CODE_ERROR]: mapApplyPromoCodeErrorToEvent
};

export const itlyBeacon = createMiddleware(itlyReduxBeaconEventsMap, itlyReduxBeaconTarget);

export function analyticsWrapper<T>(component: ComponentType<T>, defaultProperties = {}): ComponentType<T> {
  return track(defaultProperties, { dispatch: dispatchItly, dispatchOnMount: false })(component);
}

