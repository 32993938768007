//This is a temporary file. If the test wins, we will update the Top Menu JSON CMS block with this content
// and remove this file.
export const photoBooksNavABTestItems = [
  {
    'name': 'Signature Photo Books',
    'path': '/photo-books',
    'align': null,
    'children':
      [
        {
          'name': 'Layflat Photo Album',
          'path': '/photo-books/layflat-photo-album'
        },
        {
          'name': 'Hardcover Photo Book',
          'path': '/photo-books/hardcover-books'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'By Type',
    'path': '/photo-books',
    'align': null,
    'children':
      [
        {
          'name': 'All Photo Books',
          'path': '/photo-books'
        },
        {
          'name': 'Layflat Photo Books',
          'path': '/photo-books/by-feature/layflat'
        },
        {
          'name': 'Hardcover Photo Books',
          'path': '/photo-books/hardcovers'
        },
        {
          'name': 'Softcover Photo Books',
          'path': '/photo-books/paperback-photo-books'
        },
        {
          'name': 'Coffee Table Books',
          'path': '/photo-books/coffee-table-photo-books'
        },
        {
          'name': 'Board Book',
          'path': '/photo-books/baby-board-book'
        },
        {
          'name': 'Scrapbook Album',
          'path': '/photo-books/scrapbook-album'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'TIPS & TRICKS',
    'path': '/tips-and-tricks#photo-books',
    'align': null,
    'children':
      [
        {
          'name': 'Compare Photo Books',
          'path': '/photo-books/photo-book-comparison-guide'
        },
        {
          'name': 'How to Make a Book',
          'path': '/photo-books/how-to-make-a-photo-book'
        },
        {
          'name': 'Album Title Ideas',
          'path': '/diy/photo-album-title-ideas'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'By Occasion',
    'path': '/photo-books/by-occasion',
    'align': 'right',
    'children':
      [
        {
          'name': 'Wedding',
          'path': '/wedding/photo-albums'
        },
        {
          'name': 'Travel',
          'path': '/photo-books/by-occasion/travel'
        },
        {
          'name': 'Year in Review',
          'path': '/photo-books/year-in-review-photo-album'
        },
        {
          'name': 'Family',
          'path': '/photo-books/by-occasion/family'
        },
        {
          'name': 'Baby & Kids',
          'path': '/photo-books/by-feature/baby-albums'
        },
        {
          'name': 'Everyday',
          'path': '/photo-books/by-feature/everyday'
        }
      ],
    'isGatsbyPage': true
  },
  {
    'name': 'Design Services',
    'path': 'https://www.artifactuprising.com/album-design-service',
    'align': 'right',
    'children':
      [
        {
          'name': 'Album Design Services',
          'path': '/photo-books/photo-album-design-service'
        },
        {
          'name': 'Photo Curation Services',
          'path': '/photo-books/curation-services'
        }
      ],
    'isGatsbyPage': true
  }
];